import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Button } from 'semantic-ui-react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';

import galleryLeftSVG from '../../../icons/left-key.svg';
import galleryRightSVG from '../../../icons/right-key.svg';
import galleryPlaySVG from '../../../icons/play.svg';
import galleryPauseSVG from '../../../icons/pause.svg';
import galleryFullScreenSVG from '../../../icons/fullscreen.svg';
import galleryBackDownSVG from '../../../icons/back-down.svg';

const ImageGallery = loadable(() => import('react-image-gallery'));

const renderLeftNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-left-nav primary basic"
      disabled={disabled}
      onClick={onClick}
      aria-label="Go to previous slide"
    >
      <Icon name={galleryLeftSVG} size="48px" fill="black" />
    </Button>
  );
};
const renderRightNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-right-nav primary basic"
      disabled={disabled}
      onClick={onClick}
      aria-label="Go to next slide"
    >
      <Icon name={galleryRightSVG} size="48px" />
    </Button>
  );
};

const renderPlayPauseButton = (onClick, isPlaying) => (
  <Button
    type="button"
    className="image-gallery-icon image-gallery-play-button basic primary"
    onClick={onClick}
    aria-label="Play or Pause Slideshow"
  >
    {isPlaying ? (
      <Icon name={galleryPauseSVG} size="48px" />
    ) : (
      <Icon name={galleryPlaySVG} size="48px" />
    )}
  </Button>
);

const renderFullscreenButton = (onClick, isFullscreen) => {
  return (
    <Button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button primary basic"
      onClick={onClick}
      aria-label="Open Fullscreen"
    >
      {isFullscreen ? (
        <Icon name={galleryBackDownSVG} size="48px" />
      ) : (
        <Icon name={galleryFullScreenSVG} size="48px" />
      )}
    </Button>
  );
};

const ImageGalleryTemplate = ({ items }) => {
  const { settings } = config;
  const renderItems = items.filter(
    (content) =>
      settings.imageObjects.includes(content['@type']) && content.image_field,
  );
  const imagesInfo = renderItems.map((item) => {
    return {
      original: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/large`,
      thumbnail: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/thumb`,
      description: (
        <>
          <div className="title">{item.title}</div>
          <div className="description">{item.description}</div>
          <div className="download">
            <a
              href={`${flattenToAppURL(item['@id'])}/@@images/${
                item.image_field
              }/huge`}
              download
            >
              download
            </a>
          </div>
          <div className="credits">{item.copyright_and_sources}</div>
        </>
      ),
    };
  });

  return (
    renderItems.length > 0 && (
      <ImageGallery
        items={imagesInfo}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderPlayPauseButton={renderPlayPauseButton}
        renderFullscreenButton={renderFullscreenButton}
        lazyLoad={true}
      />
    )
  );
};

ImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGalleryTemplate;
