import { defineMessages } from 'react-intl';
import { insertInArray, reorderArray } from '@plone/volto/helpers/Utils/Utils';

const messages = defineMessages({
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  BlockWidth: {
    id: 'Block Width',
    defaultMessage: 'Block Width',
  },
});

export const imageBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  if (formData.url) {
    schema.fieldsets = reorderArray(schema.fieldsets, 2, 1);
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'description',
      1,
    );
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'title',
      1,
    );

    schema.properties.styles.schema.fieldsets[0].fields = [
      'blockWidth:noprefix',
      '--image-aspect-ratio',
      ...schema.properties.styles.schema.fieldsets[0].fields,
    ];

    schema.properties.styles.schema.properties['blockWidth:noprefix'] = {
      widget: 'blockWidth',
      title: intl.formatMessage(messages.BlockWidth),
      default: 'default',
      filterActions: ['narrow', 'default', 'layout', 'full'],
    };

    schema.properties.styles.schema.properties['--image-aspect-ratio'] = {
      widget: 'select',
      title: 'Aspect Ratio',
      choices: [
        ['1', '1:1'],
        ['16 / 9', '16/9'],
      ],
      default: '1',
    };

    schema.properties.description = {
      title: intl.formatMessage(messages.Description),
      widget: 'textarea',
    };
    schema.properties.title = {
      title: intl.formatMessage(messages.Title),
    };
  }
  schema.properties.align.default = 'center';
  schema.properties.align.actions = ['left', 'right', 'center'];

  schema.properties.size.default = 'l';
  schema.properties.size.disabled =
    formData.align === 'full' ||
    formData.align === 'wide' ||
    formData.align === 'center';

  return schema;
};
